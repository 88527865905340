export function JsonViewer({
  json,
  title,
  className,
}: {
  json: Record<string, unknown> | Record<string, unknown>[]
  title?: string
  className?: string
}) {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }
  return (
    <details className={className}>
      <summary>{title || 'JSON'}</summary>
      <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap' }}>
        <code>{JSON.stringify(json, undefined, 2)}</code>
      </pre>
    </details>
  )
}
