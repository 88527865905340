import { API_ENDPOINTS } from 'routes/constants'
import { validateResponseStatusCode } from 'routes/Integrations/helpers'
import {
  ScenarioTemplateCompact,
  ScenarioTemplateExtended,
} from 'routes/Scenarios/helpers/types'

export async function fetchIntegrationScenarios(integrationKey: string) {
  try {
    const res = await fetch(`${API_ENDPOINTS.CONNECTORS}/${integrationKey}`)

    validateResponseStatusCode(res, `Connector not found: ${integrationKey}`)

    const connector = await res.json()

    const { scenarios, scenarios_amount } = await fetchIntegrationScenariosData(
      connector.appUuid,
      integrationKey,
    )

    return { scenarios, scenarios_amount }
  } catch (e) {
    return {
      message: `Connector ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

async function fetchIntegrationScenariosData(
  integrationUuid: string,
  integrationKey: string,
) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.SCENARIO_TEMPLATES}?appUuid=${integrationUuid}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Scenarios not found: ${integrationKey}`,
    )

    const scenarios = (await res.json()).items.map(
      (scenario: Record<string, unknown>) => ({
        id: scenario.id,
        key: scenario.key,
        name: scenario.name,
        popularity: scenario?.popularity ?? null,
        description: scenario?.description ?? null,
        shortDescription: scenario?.shortDescription ?? null,
        connectors: scenario?.connectors ?? [],
      }),
    ) as ScenarioTemplateCompact[]

    return {
      scenarios: sortScenariosByPopularity(scenarios),
      scenarios_amount: scenarios?.length,
    }
  } catch (e) {
    return {
      message: `Integration Scenarios for ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export async function fetchIntegrationSingleScenario(
  integrationKey: string,
  scenarioKey: string,
) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.SCENARIO_TEMPLATES}/by-key/${scenarioKey}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Scenario not found: ${integrationKey}/${scenarioKey}`,
    )

    return (await res.json()) as ScenarioTemplateExtended
  } catch (e) {
    return {
      message: `Integration Scenario for ${integrationKey}/${scenarioKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

const sortScenariosByPopularity = (scenarios: ScenarioTemplateCompact[]) => {
  return scenarios.sort((a, b) => (b?.popularity ?? 0) - (a?.popularity ?? 0))
}
