import { PropsWithChildren } from 'react'
import { Provider as ReactWrapProvider } from 'react-wrap-balancer'
import { Footer, Header } from 'routes/components/Layout'
import { BookCalendlyProvider } from 'routes/components/Shared'

export type DefaultPageLayoutProps = {
  className?: string
  hideAdvertiseBlock?: boolean
}

export function DefaultPageLayout({
  className,
  hideAdvertiseBlock,
  children,
}: PropsWithChildren<DefaultPageLayoutProps>) {
  return (
    <ReactWrapProvider>
      <BookCalendlyProvider>
        <Header />
        <main className={className}>{children}</main>
        <Footer hideAdvertiseBlock={hideAdvertiseBlock} />
      </BookCalendlyProvider>
    </ReactWrapProvider>
  )
}
