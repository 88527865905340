import { AppProps } from 'next/app'

import * as Sentry from '@sentry/react'

import { FontsProvider } from 'providers/FontsProvider'
import { PostHogProvider } from '../providers/PostHogProvider'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import { HeadMetatags, TrackingContextProvider } from 'routes/components/Layout'
import { ErrorBoundary } from 'react-error-boundary'

import 'styles/variables.css'
import 'styles/globals.css'
import 'styles-scss/index.scss'

if (process.env.NEXT_PUBLIC_ENABLE_TRACKING) {
  Sentry.init({
    dsn: 'https://ab68abc0f6663746bafb1e2b1d82e33c@o956397.ingest.sentry.io/4506388004864000',
  })
}

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  return (
    <NuqsAdapter>
      <FontsProvider>
        <TrackingContextProvider>
          <PostHogProvider>
            <HeadMetatags
              title='Universal integration layer for your SaaS product'
              description={
                'Connect to any app your customers use. Build seamless integration logic and UI. Scale it to every relevant app with AI.'
              }
            />
            <ErrorBoundary fallback={<AppErrorFallback />} onError={logError}>
              {process.env.NEXT_PUBLIC_ENABLE_TRACKING ? (
                <GTMProvider
                  state={{
                    id: 'GTM-P9XKTRTC',
                  }}
                >
                  <Component {...pageProps} />
                </GTMProvider>
              ) : (
                <Component {...pageProps} />
              )}
            </ErrorBoundary>
          </PostHogProvider>
        </TrackingContextProvider>
      </FontsProvider>
    </NuqsAdapter>
  )
}

function AppErrorFallback() {
  return (
    <div className={'global-error-boundary'}>
      <h2>Something went wrong with the application</h2>
      <button
        className={'btn btn--primary'}
        onClick={() => window.location.reload()}
      >
        Refresh Application
      </button>
    </div>
  )
}

function logError(error: Error, errorInfo: { componentStack: string }) {
  if (process.env.NEXT_PUBLIC_ENABLE_TRACKING) {
    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
        error: error.toString(),
      },
    })
  }
  if (process.env.NODE_ENV === 'development') {
    console.error('App Error:', error, errorInfo)
  }
}
