import { ReactNode } from 'react'
import clsx from 'clsx'
import classes from './Container.module.scss'

type ContainerProps = {
  children: ReactNode
  grid?: boolean
  className?: string
}

export function Container({ children, grid, className }: ContainerProps) {
  return (
    <div
      className={clsx(
        classes.wrapper,
        grid && classes.wrapper__grid,
        className,
      )}
    >
      {children}
    </div>
  )
}
